﻿@import 'variables';
@import 'mixins';
@import 'helpers';

ul.list {
  margin: 0 -15px;
  margin-top: 15px;
  margin-bottom: -15px;
  max-height: 57vh;
  overflow: auto;

  li {
    display: flex;
    flex: 0 1 auto;
    padding: 10px 15px;
    border-bottom: 1px solid $bgGray;

    &:hover,
    &.active {
      background-color: $bgGray;
    }

    * {
      cursor: pointer;
    }

    .left {
      @include latoFont();
      min-width: 35px;
      font-weight: bold;
      font-size: 16px;
    }

    .center {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
      color: $primaryColor;
      font-weight: bold;
      @include latoFont();
      font-size: 16px;
      p {
        font-size: 14px;
        white-space: nowrap;
        font-weight: normal;
        line-height: 18px;
      }
    }

    &:last-child {
      border-bottom: none;
      /* padding-bottom: 0; */
    }
  }
}
