﻿@import 'variables';

.background-primary {
  background-color: $primaryColor;
}
.color-primary {
  color: $primaryColor;
}
.color-success {
  color: $successColor;
}
.color-error {
  color: $errorColor;
}
.color-gray {
  color: $gray;
}
.color-darkgrey {
  color: $darkGray
}
.color-lightblue {
  color: $primaryColorLightAccessible;
}
.background-light-gray {
  background-color: $lightGray;
}
.background-gray {
  background-color: $gray;
}
.background-dark-gray {
  background-color: $darkGray;
}
.background-white {
  background-color: $white !important;
}
.color-white {
  color: $white !important;
}
