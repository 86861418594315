﻿.ng-select div.ng-select-container {
    padding: 15px 10px 15px 20px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container div.ng-input {
    position: absolute;
    left: 8px;
    top: 18px;
}

.ng-select .ng-select-container .ng-value-container div.ng-placeholder,
.ng-select .ng-select-container .ng-input input,
.ng-select .ng-select-container .ng-value-container div.ng-value,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option span.ng-option-label {
    color: $primaryColor;
    font-size: 16px;
    font-weight: bold;
}

.ng-select span.ng-arrow-wrapper {
    top: 2px;
    left: -15px;
    transition: all 0.2s ease;
}

.ng-select.ng-select-opened > .ng-select-container span.ng-arrow-wrapper {
    top: -4px;
    transition: all 0.2s ease;
}

.ng-select .ng-arrow-wrapper span.ng-arrow {
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: $primaryColor;
    height: 14px;
    width: 14px;
    transform: rotate(135deg);
}