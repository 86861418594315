/* Title */
.mdc-dialog__title, .mat-mdc-dialog-title, .mdc-dialog__title, .mat-mdc-dialog-title, h3 {
  color: var(--aaa-color-dark) !important;
  font-family: var(--ion-font-family) !important;
  font-size: 1.13rem !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

/* Subtitle */
.dialog, .not-a-member h3, .not-a-member h1 {
  margin-bottom: 0 !important;
  font-weight: 600 !important;
}

/* Text */
.dialog p, .dialog label, .mat-mdc-dialog-content, .phone-numbers-hint, .feedback-description {
  color: var(--aaa-color-medium) !important;
  font-family: var(--ion-font-family) !important;
  font-weight: 400 !important;
  font-size: .8rem !important;
  text-transform: unset !important;
}

/* Primary button */
.mat-mdc-dialog-actions .btn, .app-link-button, .app-button, #dialog-content ~ .cancel-feedback-option_secondary-content .app-button, .app-button.cozy {
  font-size: .9rem !important;
  font-family: var(--ion-font-family) !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  background-color: var(--aaa-color-primary);
  border-radius: .75rem !important;
  padding: 0.88rem !important;
  border: unset !important;
  margin: 0 !important;
  color: var(--aaa-color-primary-contrast) !important;
}

/* Secondary button */
.dismiss-button.btn.primary, .dismiss-button .btn.primary, .app-button#dialog-dismiss-submit, #duplicate-dialog a, #dialog-dismiss-submit button {
  background-color: var(--aaa-color-secondary) !important;
  color: var(--aaa-color-secondary-contrast) !important;
  padding: 0.88rem !important;
}

/* Dialog Button Primary */
app-link-button .btn.link.primary:not(.inverted) {
  background-color: var(--aaa-color-primary) !important;
  color: var(--aaa-color-primary-contrast) !important;
}
/* Dialog Button Call AAA and primary inverted */
app-button .app-button.primary.color-inverted,
.call-aaa-button app-link-button .btn.link.primary:not(.inverted) {
  background-color: var(--aaa-color-secondary) !important;
  color: var(--aaa-color-secondary-contrast) !important;
}

/* Outline button */
#feedback-options button,
.other-comment textarea {
  --aaa-outline-border: #cbd5e1;
  font-weight: 400 !important;
  text-transform: initial !important;
  background: unset !important;
  border: var(--aaa-outline-border) 1px solid !important;
  color: var(--aaa-color-dark) !important;
  font-family: var(--aaa-font-family) !important;
  outline: none !important;
  border-radius: .75rem !important;
}

/* Inputs */
.modal-form input {
  box-shadow: unset !important;
  font-size: 1rem !important;
  border-color: var(--aaa-color-secondary) !important;
  background: var(--aaa-color-light-base) !important;
  border-radius: 10px !important;
}

.modal-form input:focus {
  outline: var(--aaa-color-dark) 2px solid !important;
}

.other-comment textarea {
  font-size: 1rem !important;
  padding: .85rem !important;
}

/* Errors */
.text-error, .error-hint {
  font-size: .8rem !important;
  font-weight: 400 !important;
  margin-top: 0 !important;
  color: var(--aaa-color-danger) !important;
}

/* Remove icon from error */
.material-icons-sharp.icon {
  display: none
}

/* Close Button (X) */
.cdk-overlay-container .mat-mdc-dialog-container .dialog g {
  stroke: var(--aaa-color-dark) !important;
}

.mat-dialog-close {
  padding: 20px 20px 0 !important;
}

/* Containers */
.mat-mdc-dialog-container {
  border-radius: .75rem !important;
  width: 95vw !important;
}

.mat-mdc-dialog-actions, .dialog-actions, .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0 10px !important;
  gap: 1rem !important;
}

.feedback-options, .cancel-feedback-option_secondary-content {
  padding-bottom: 1rem !important
}

/* Tow Distance Warning Dialog */
.dialog-content .text-message {
  margin: 0;
  padding: 0 10px 15px;
}

/* Vehicle Selection Empty Icon */
aaa-icon[name='car-off'] tabler-icon {
  stroke-width: 1px;
}
