﻿@import 'variables';
@import 'mixins';


$mat-dialog-padding: 24px !default;
$mat-dialog-border-radius: .75rem !default;
$mat-dialog-max-height: 65vh !default;
$mat-dialog-button-margin: 8px !default;

.mat-mdc-dialog-container {
    display: block;
    padding: $mat-dialog-padding;
    border-radius: $mat-dialog-border-radius;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;

    // The dialog container should completely fill its parent overlay element.
    width: 100%;
    height: 100%;

    // Since the dialog won't stretch to fit the parent, if the height
    // isn't set, we have to inherit the min and max values explicitly.
    min-height: inherit;
    max-height: inherit;

    @media screen and (max-width: $break-small) {
      margin-bottom: 1.2rem;
    }
}

.mat-mdc-dialog-content {
    display: block;
    margin: 0 $mat-dialog-padding * -1;
    padding: 0 $mat-dialog-padding;
    max-height: $mat-dialog-max-height;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.mat-mdc-dialog-title {
    margin: 0;
    display: block;
}

.mat-mdc-dialog-actions {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 52px;
    align-items: center;

    // Pull the actions down to avoid their padding stacking with the dialog's padding.
    margin-bottom: -$mat-dialog-padding;

    &[align='end'] {
        justify-content: flex-end;
    }

    &[align='center'] {
        justify-content: center;
    }

    .mat-mdc-button-base + .mat-button-base {
        margin-left: $mat-dialog-button-margin;

        [dir='rtl'] & {
            margin-left: 0;
            margin-right: $mat-dialog-button-margin;
        }
    }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
.mat-dialog-close {
    align-self: flex-end;
    order: -1;
    border: none;
    background-color: $bgContainer;
    cursor: pointer;
}

.cdk-overlay-container {
    z-index: 30000 !important;
    .cdk-overlay-pane {
        align-self: flex-end;
        max-width: 90vw !important;
        max-width: calc(100vw - 30px) !important;
        min-width: 280px;
        @media screen and (min-width: $break-small + 1) {
            min-width: 345px;
            max-width: 80vw !important;
        }
        @media screen and (min-width: $break-medium + 1) {
            min-width: 345px;
            max-width: 800px !important;
        }
    }

    .mat-mdc-dialog-container {
        padding: 0;
        box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75);
        color: $textColor;
        background-color: $bgContainer;
        border-radius: $mat-dialog-border-radius;

        .dialog {
            display: flex;
            flex-flow: column nowrap;
            padding: 5px;
            margin-bottom: 40px;

            * {
                flex: 1 0 auto;
                box-sizing: border-box;
            }
        }

        .mat-mdc-dialog-title {
            margin: 0 0 16px 0;
            font-size: 18px;
            font-weight: bold;
            @include latoFont();
            color: $textColor;

            display: flex;
            flex-flow: row nowrap;

            * {
                flex: 1 0 auto;
            }

            .icon {
                border: none;
                background: initial;
                flex: 0 0 16px;
                background: transparent;
                cursor: pointer;
            }
        }

        .mat-mdc-dialog-content {
            display: flex;
            flex-flow: column wrap;
            margin: 0;
            padding: 0;
            max-height: 40vh;
            @include latoFont();
            font-size: 14px !important;
            color: $primaryColor;
            line-height: 1.26;
            letter-spacing: 0px;
            &.tall {
                flex-flow: column nowrap;
                max-height: 80vh;
            }
            > :not(.btn):not(app-link-button) {
                margin-right: 20px;
                margin-left: 20px;
            }
            .btn {
                margin: 15px 10px 15px 10px;
            }
            app-link-button {
                margin: 15px 20px 15px 0px;
            }
        }

        .mat-mdc-dialog-actions {
            padding: 0;
            margin: 10px;
            padding-top: 25px;
            padding-bottom: 25px;
            justify-content: flex-end;
            app-link-button {
                margin-top: 0;
            }

            #dialog-dismiss-submit {
                background: transparent;

                &.primary {
                    background-color: $primaryColor;
                }
            }

            .btn {
                text-transform: uppercase;
                &:hover {
                    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.5);
                }
            }
        }

        .btn,
        .btn-sm {
            text-decoration: none;
            &.info {
                cursor: inherit;
            }
        }

        .btn-sm {
            padding: 10px;
        }

        .bordered-button {
            border: 1px solid $primaryColor;
            color: $primaryColor;
            margin: 3px;
        }
    }
}

@media only screen and (min-width: $modal-breakpoint + 1) {
    .cdk-overlay-container {
        .cdk-overlay-pane {
            align-self: unset;
        }

        .mat-mdc-dialog-container {
            border-radius: $mat-dialog-border-radius;
            .dialog {
                margin-bottom: 0;
            }
        }
    }
}

.battery-quotes-modal {
  .dialog {
    max-width: 380px;
  }

  @media screen and (max-width: $break-small + 1) {
    .mat-mdc-dialog-content.tall {
      max-height: 55vh !important;
    }

    .dialog {
      margin-bottom: 10px !important;
    }
   }

}

.iframe-dialog {
  .dialog {
    min-width: 600px;
    .mat-mdc-dialog-content {
       margin-left: 0;
       margin-right: 0;
    }
  }

  @media screen and (max-width: $break-small) {
    .dialog {
      min-width: 100%;
    }
  }
}
