﻿[hidden] {
    display: none !important;
    visibility: hidden !important;
}

.noscroll {
    overflow: hidden !important;
}

.noborder {
    border: none !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.wordbreak {
    word-break: break-all;
}

.copy {
    font-size: $defaultFontSize - 2px;
    font-weight: normal;
    letter-spacing: 0px;
}

.rotate-45 {
    transform: rotate(45deg);
}

.responsive {
    max-width: 100%;
}

.text-error {
    color: $errorColor;
}

.font-size-medium {
    font-size: medium;
}

.font-size-large {
    font-size: large;
}

.font-size-larger {
    font-size: larger;
}
